<!-- login -->
<template>
  <div class="login-page" :style="{backgroundImage: `url(${logonBackgroundMap})`}">
    <ics-page-header :project-title="projectTitle" :layout="[]" />
    <div class="find-pass-word-inner">
      <div class="find-pass-word-form">
        <div class="find-pass-word-content">
          <div style="padding-top: 80px;text-align: center;">
            <p style="font-size: 14px;font-weight: bold;padding: 20px 0px; margin-left: 20px;">
              等待开户绑卡审核中。。。
            </p>
            <p v-if="accountDetail.eaccountStatus === '30'" style="font-size: 14px;font-weight: bold;padding: 18px 0px;">
              开户绑卡审核通过
            </p>
            <el-button v-loading="loading.submit" class="add-btn" style="margin-top: 40px;" @click="$router.push({ name: 'account'})">
              返回电子账簿
            </el-button>
            <el-button v-if="!accountDetail.jumpbandingCardFalg && accountDetail.eaccountStatus === '30'" v-loading="loading.submit" class="add-btn" type="primary" style="margin-top: 40px;" @click="apply">
              签署申请
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <ics-dialog-inner width="40%" :loading="loading.submit" :visible.sync="dialog.visible" :title="dialog.title" submit-title="提交" cancel-title="取消" class="form-dialog" @submit="submitCode" @close="closeCode">
      <div class="form-area">
        <div class="form-inner">
          <el-form ref="appForm" :model="appForm" :rules="rules" label-width="140px" label-position="right">
            <el-row :gutter="80" type="flex" justify="center" align="middle" style="margin-top: 30px;">
              <el-col :span="16">
                <el-col :span="16">
                  <p style="width: 310px;color: #1D7FF2;font-size: 16px;line-height: 40px;margin: -20px 0px 20px -37px;">
                    开户人手机号码：&nbsp;&nbsp;{{ utils.isEffectiveCommon(phoneNumber) }}
                  </p>
                </el-col>
              </el-col>
            </el-row>
            <el-row :gutter="80" type="flex" justify="center" align="middle">
              <el-col :span="16">
                <el-form-item prop="sMSCode" label="验证码：">
                  <el-input v-model="appForm.code" style="width:80%;" type="number" placeholder="请输入验证码" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
    </ics-dialog-inner>
  </div>
</template>
<script>
import { cookie } from '@/assets/js/config'
import api from '@/assets/js/api'
import IcsDialogInner from '@/components/dialog-inner'
import IcsPageHeader from '@/components/page-header'
import {profiles} from "../../../assets/js/config";
export default {
  components: { IcsDialogInner, IcsPageHeader },
  data () {
    return {
      // enrollmentWebUrl: 'https://elr.cpcn.com.cn/EnrollmentWeb/ssoLogin.do',
      // enrollmentWebUrl: 'https://test.cpcn.com.cn/EnrollmentWeb/ssoLogin.do',
      enrollmentWebUrl: '',
      projectTitle: '',
      logonBackgroundMap: '',
      userId: this.$route.query.userId,
      firmId: this.$route.query.firmId,
      loading: {
        submit: false,
        detail: false
      },
      appForm: {
        code: ''
      },
      phoneNumber: '',
      accountDetail: {
        bankInfo: {}
      },
      dialog: {
        visible: false,
        title: '验证信息'
      },
      notes: '',
      form: {},
      rules: {
      }
    }
  },
  watch: {},
  created () {
    if (this.userId) {
      this.getDetail()
    }
    this.zhongUrl()
    this.getData()
  },
  methods: {
    getData () {
      return new Promise((resolve, reject) => {
        this.utils.getPersonalization().then(result => {
          this.logonBackgroundMap = result.logonPage.backgroundMap
          this.projectTitle = result.global.wangName+process.env.VUE_APP_PROJECT_NAME
          resolve()
        }).catch(e => {
          reject(e)
        })
      })
    },
    zhongUrl () {
      this.api.system.account.zhongUrl().then(result => {
        this.enrollmentWebUrl = result.data.data
      }).finally(() => {
        this.loading.detail = false
      })
    },
    getDetail () {
      this.loading.detail = true
      this.api.system.account.getBankDetail(this.userId).then(result => {
        const data = result.data.data
        if (data.bankInfo.provinceCode && data.bankInfo.cityCode && data.bankInfo.districtCode) {
          data.bankInfo.dsEnterpriseAddress = [data.bankInfo.provinceCode, data.bankInfo.cityCode, data.bankInfo.districtCode]
        }
        this.accountDetail = data || {}
      }).finally(() => {
        this.loading.detail = false
      })
    },
    apply () {
      this.loading.submit = true
      this.api.system.account.eJz7703(this.userId).then(result => {
        const eJz7703 = JSON.parse(result.data.data)
        console.log(eJz7703)
        if (['10','30'].includes(eJz7703.status) && eJz7703.code === '2000') {
          this.$message.success(eJz7703.responseMessage ? eJz7703.responseMessage : eJz7703.message)
          this.loading.submit = false
          this.phoneNumber = eJz7703.phoneNumber
          this.dialog.visible = true
        } else {
          this.$message.error(eJz7703.responseMessage ? eJz7703.responseMessage : eJz7703.message)
          this.loading.submit = false
        }
      }).catch(e => {
        this.loading.submit = false
      })
    },
    submitCode () {
      if (!this.appForm.code) {
        this.$message.error('请输入验证码')
        return false
      }
      window.open(`${this.enrollmentWebUrl}?phone=${this.phoneNumber}&code=${this.appForm.code}`, '_blank')
      this.dialog.visible = false
      this.$router.push({ name: 'account'})
    },
    closeCode () {
      this.appForm.code = ''
    }
  }
}
</script>
<style lang="less" scoped>
.login-page {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: url("../../../assets/images/demo/login-big.png");
  background-size: 100% 100%;
}
.find-pass-word-inner {
  width: 840px;
  height: 550px;
  border-radius: 5px;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -370px;
  margin-top: -245px;
  padding: 40px 30px 40px;
  background-color: rgba(0, 0, 0, 0.6);
  .logo-inner {
    margin: 0 auto 10px;
    width: 380px;
    height: 30px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  h2 {
    font-size:25px;
    line-height: 1;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
    margin-bottom: 30px;
  }
  .find-pass-word-content {
    background: #fff;
    margin-top: 30px;
    width: 100%;
    height: 380px;

    .find-pass-word-content-title {
      border-bottom: 1px solid lavender;
    }
  }
}

</style>
<style scoped>
.el-step >>> .el-step__icon {
  width: 40px !important;
  height: 40px !important;
}
.el-step >>> .el-step__line{
  top: 18px !important;
}
.el-step >>>  .is-process .el-step__icon-inner {
  color: #000 !important;
}
.el-step >>> .is-process{
  color: #fff !important;
}
</style>
